<template>

  <!-- Market -->
  <div id="story-mode-market-potential">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">

      <!-- Content -->
      <div
        class="content"
        :class="{ 'position-relative': statusOfCompleted }"
      >
        <v-app>
          <!-- Header Page Header -->
          <header class="page-header">
            <dashboard-header
              :class="{ 'stamp': statusOfCompleted }"
              :dashboard-items="dashboardPromotion"
              :title="$t('pages.businessGuide.marketPotential.title')"
              :guide-visible="guideVisible"
              :guide-visible-first-time="guideVisibleFirstTime"
              :completed-step-name="'marketPotentialCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="openInfo"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />

            <!-- Divider -->
            <hr>
            <!-- /Divider -->

          </header>
          <!-- /Header Page Header -->

          <!-- Info Guide Expand Panel -->
          <info-guide
            v-if="guideVisibleFirstTime"
            class="mb-2"
            type="marketPotentialExamples"
            visible
            @click="guideVisibleFirstTime = !guideVisibleFirstTime"
          />
          <!-- Info Guide Expand Panel -->

          <!-- Ai Buddy Drawer -->
          <ai-buddy-drawer
            :visible="aiBuddyDrawerVisible"
            section-name="MarketPotential"
            help-me-write
            type="market-potential"
            @close="aiBuddyDrawerVisible = false"
          />
          <!-- /Ai Buddy Drawer -->
        </v-app>

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <div class="market-potential-wrapper">
          <!-- Loader -->
          <loader v-if="loading" />
          <!-- /Loader -->

          <!-- Business plan additional info -->
          <business-plan-additional-info
            completed-step-name="marketPotentialCompleted"
            section-name="MarketPotential"
            show-ai-buddy
            @ai-buddy-open="aiBuddyDrawerVisible = true"
          />
          <!-- /Business plan additional info -->

          <!-- Header -->
          <business-guide-header class="mb-3 mb-md-0 mt-4 mt-md-0">
            <template #left>
              <p class="text-lead xl m-0 mb-md-3 mt-md-4">
                {{ $t('pages.businessGuide.marketPotential.subtitle') }}
              </p>
            </template>
            <template #right>
              <erase-icon-button
                v-if="removeButtonVisible"
                @click="onEraseFromTemplate"
              />
            </template>
          </business-guide-header>
          <!-- /Header -->

          <el-row :gutter="20" class="mt-4">
            <el-col :md="14" :lg="16" :class="{ 'completed-card': $store.state.idea.storyMode.common.completedSteps.marketPotentialCompleted }">
              <!-- Completed Overlay -->
              <div
                v-if="!canEdit || $store.state.idea.storyMode.common.completedSteps.marketPotentialCompleted"
                class="completed-overlay"
                @click="dialogMarketPotentialTrigger"
              />
              <!-- /Completed Overlay -->
              <el-row>
                <el-col>
                  <div
                    class="potential-card enabled"
                    :class="{ 'filled': totalRevenue }"
                    @click="dialogMarketPotentialTrigger('total')"
                  >
                    <el-button v-if="!totalRevenue" type="plus-round-xs" icon="el-icon-plus" />
                    <p class="title">
                      {{ $t('pages.businessGuide.marketPotential.steps.totalAddressableMarket.title') }}
                    </p>
                    <p v-if="totalRevenue" class="number-holder">
                      {{ currencySymbol }}{{ getFormattedAmount(totalRevenue) }}
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-if="totalRevenue" v-bind:svg-inline="''" v-bind:class="'pen'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#3e474f" opacity=".5" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
                  </div>
                </el-col>
                <el-col>
                  <div
                    class="potential-card"
                    :class="{ 'enabled': totalRevenue, 'filled': availableTotalRevenue }"
                    @click="dialogMarketPotentialTrigger('available')"
                  >
                    <el-button v-if="!availableTotalRevenue" type="plus-round-xs" icon="el-icon-plus" />
                    <p class="title">
                      {{ $t('pages.businessGuide.marketPotential.steps.serviceableAvailableMarket.title') }}
                    </p>
                    <p v-if="availableTotalRevenue" class="number-holder">
                      {{ currencySymbol }}{{ getFormattedAmount(availableTotalRevenue) }}
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-if="availableTotalRevenue" v-bind:svg-inline="''" v-bind:class="'pen'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#3e474f" opacity=".5" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
                  </div>
                </el-col>
                <el-col>
                  <div
                    class="potential-card"
                    :class="{ 'enabled': availableTotalRevenue, 'filled': shareTotalRevenue }"
                    @click="dialogMarketPotentialTrigger('share')"
                  >
                    <el-button v-if="!shareTotalRevenue" type="plus-round-xs" icon="el-icon-plus" />
                    <p class="title">
                      {{ $t('pages.businessGuide.marketPotential.steps.shareOfMarket.title') }}
                    </p>
                    <p v-if="shareTotalRevenue" class="number-holder">
                      {{ currencySymbol }}{{ getFormattedAmount(shareTotalRevenue) }}
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-if="shareTotalRevenue" v-bind:svg-inline="''" v-bind:class="'pen'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#3e474f" opacity=".5" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :md="10" :lg="8">
              <div class="market-display" :class="{'filled': totalRevenue}">
                <div class="market-heading">
                  <h3 v-if="totalRevenue">
                    {{ $t('pages.businessGuide.navigation.marketPotential') }}
                  </h3>
                </div>
                <template v-if="!totalRevenue">
                  <div class="market-char_wrapper">
                    <div class="market-char market-char_total">
                      <svg viewBox="0 0 80 80">
                        <circle class="circle" cx="40" cy="40" r="40" />
                      </svg>
                    </div>
                    <div class="market-char market-char_available">
                      <svg viewBox="0 0 80 80">
                        <circle class="circle" cx="40" cy="40" r="40" />
                      </svg>
                    </div>
                    <div class="market-char market-char_share">
                      <svg viewBox="0 0 80 80">
                        <circle class="circle" cx="40" cy="40" r="40" />
                      </svg>
                    </div>
                  </div>
                </template>

                <template v-if="totalRevenue">
                  <div class="market-char_wrapper">
                    <div class="market-char market-char_total">
                      <ib-tooltip popper-class="market-tooltip" :open-delay="0">
                        <div slot="content">
                          <span>TAM</span>
                          <br>
                          <p>{{ currencySymbol }}{{ getFormattedAmount(totalRevenue) }}</p>
                        </div>
                        <svg viewBox="0 0 80 80">
                          <circle class="circle" cx="40" cy="40" r="40" />
                        </svg>
                      </ib-tooltip>
                    </div>
                    <div v-if="availableTotalRevenue" class="market-char market-char_available">
                      <ib-tooltip popper-class="market-tooltip" :open-delay="0">
                        <div slot="content">
                          <span>SAM</span>
                          <br>
                          <p>{{ currencySymbol }}{{ getFormattedAmount(availableTotalRevenue) }}</p>
                        </div>
                        <svg viewBox="0 0 80 80">
                          <circle class="circle" cx="40" cy="40" r="40" />
                        </svg>
                      </ib-tooltip>
                    </div>
                    <div v-if="shareTotalRevenue" class="market-char market-char_share">
                      <ib-tooltip popper-class="market-tooltip" :open-delay="0">
                        <div slot="content">
                          <span>SOM</span>
                          <br>
                          <p>{{ currencySymbol }}{{ getFormattedAmount(shareTotalRevenue) }}</p>
                        </div>
                        <svg viewBox="0 0 80 80">
                          <circle class="circle" cx="40" cy="40" r="40" />
                        </svg>
                      </ib-tooltip>
                    </div>
                  </div>
                </template>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'research'"
        :step="'market-potential'"
        :concept-name="'marketPotentialMoreDetails'"
        :completed-step-name="'marketPotentialCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'marketPotentialCompleted'"
      :step-filled="totalRevenue !== null && availableTotalRevenue !== null && shareTotalRevenue !== null"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="marketPotentialExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Dialog Add Competitor -->
    <dialog-market-potential
      :dialog-market-potential-visible="dialogMarketPotentialVisible"
      :market-template="marketTemplate"
      :market-potential-data="marketPotentialData"
      @close-dialog-market-potential="closeDialogMarketPotential"
      @on-save-data="onSaveData"
    />
    <!-- /Dialog Add Competitor -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- /Market -->

</template>

<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import BusinessPlanAdditionalInfo from '@/views/Home/StoryMode/Components/BusinessPlanAdditionalInfo.vue'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogMarketPotential from './Dialogs/DialogMarketPotential'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'
import Loader from '@/components/Loader'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import marketPotentialDefaultData from '@/data/marketPotentialDefaultData.json'

import { mapActions, mapGetters } from 'vuex'
import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import _omit from 'lodash/omit'

export default {
  name: 'MarketPotential',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessPlanAdditionalInfo,
    AddMoreDetails,
    BusinessGuideHeader,
    DashboardHeader,
    DialogMarketPotential,
    DialogProcessCompleted,
    DialogRemoveExamples,
    EraseIconButton,
    Loader,
    MainBottomBar,
    MainNavigation
  },

  mixins: [
    MixinProcessCompleted,
    MixinGuide,
    MixinCurrencies,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      marketPotentialData: marketPotentialDefaultData,
      dialogRemoveExamplesVisible: false,
      removeExamplesLoading: false,
      totalRevenue: null,
      availableTotalRevenue: null,
      shareTotalRevenue: null,
      tooltipsVisible: true,
      tooltipsBottomVisible: true,
      dialogMarketPotentialVisible: false,
      dialogProcessCompletedVisible: false,
      marketTemplate: '',
      loading: true,
      dashboardPromotion: {
        title: 'Market Potential'
      },
      guideVisible: false
    }
  },

  computed: {
    ...mapGetters('idea', [
      'getStepIsCompleted'
    ]),

    statusOfCompleted () {
      return this.getStepIsCompleted('marketPotentialCompleted')
    },

    hasItemsFromTemplate () {
      return !this.marketPotentialData.isTouched &&
          this.marketPotentialData.isFromTemplate &&
          Object.values(_omit(this.marketPotentialData, ['id', 'isFromTemplate', 'isTouched'])).some(item => item !== null)
    },

    removeButtonVisible () {
      return this.canEdit && this.hasItemsFromTemplate
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.swotAnalysis'),
        route: 'business-guide-research-swot-analysis'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.startupCosts'),
        route: 'business-guide-set-up-startup-costs'
      }
    }
  },

  async created () {
    await this.getMarketPotentialData()
    await this.getMarketPotential()
    this.openGuide('marketPotentialGuide')
  },

  methods: {
    ...mapActions('idea', [
      'getMarketPotential',
      'deleteIdeaExamples'
    ]),

    getMarketPotentialData () {
      this.$http.get(`story-mode/research/market-potential?ideaId=${this.$store.state.idea.id}`)
        .then(response => {
          this.marketPotentialData = Object.assign({}, response.data.payload.marketPotential)
          this.$store.commit('idea/setMarketPotential', response.data.payload.marketPotential)
        }).finally(() => {
          this.calculateMarketPotential()
        })
    },

    calculateMarketPotential () {
      if (!this.marketPotentialData.total_population && !this.marketPotentialData.potential_customers && !this.marketPotentialData.annual_spending) {
        this.totalRevenue = null
      } else {
        this.totalRevenue = this.marketPotentialData.total_population * (this.marketPotentialData.potential_customers / 100) * this.marketPotentialData.annual_spending
      }

      if (!this.marketPotentialData.target_customers) {
        this.availableTotalRevenue = null
      } else {
        this.availableTotalRevenue = this.totalRevenue * (this.marketPotentialData.target_customers / 100)
      }

      if (!this.marketPotentialData.market_share) {
        this.shareTotalRevenue = null
      } else {
        this.shareTotalRevenue = this.availableTotalRevenue * (this.marketPotentialData.market_share / 100)
      }

      this.loading = false
    },

    dialogMarketPotentialTrigger (template = '') {
      if (!this.canEdit) return
      if (this.statusOfCompleted) {
        this.openedDialogProcessCompleted()

        return
      }

      this.marketTemplate = template
      if (template === 'total') {
        this.dialogMarketPotentialVisible = true
      }

      if (template === 'available' && this.totalRevenue) {
        this.dialogMarketPotentialVisible = true
      }

      if (template === 'share' && this.availableTotalRevenue) {
        this.dialogMarketPotentialVisible = true
      }
    },

    closeDialogMarketPotential () {
      this.dialogMarketPotentialVisible = false
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    onSaveData (data) {
      this.marketPotentialData = Object.assign({}, data)
      this.calculateMarketPotential()
      this.dialogMarketPotentialVisible = false
    },

    onEraseFromTemplate () {
      if (this.statusOfCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      this.deleteIdeaExamples('market-potential')
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          const marketPotential = Object.assign({ id: this.marketPotentialData.id }, marketPotentialDefaultData)
          this.marketPotentialData = marketPotential
          this.calculateMarketPotential()
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>
